.appblock {
    border-radius: 20px;
    height: 150px;
    margin: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    background-color: #fff;
    cursor: pointer;
    position: relative;
}

.appblock-inner {
    height: 100%;
    padding: 18px 30px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.appblock::before {
    content: "";
    display: block;
    position: absolute;
    width: 7px;
    height: 80px;
    top: 35px;
    background-color: aqua;
    border-radius: 0 10px 10px 0;
}

.appblock.seller::before, .appblock.seller .appblock-icon {
    background: rgba(57, 115, 103, 1);
}
.appblock.product::before, .appblock.product .appblock-icon {
    background: rgba(220, 32, 88, 1);
}
.appblock.dumping::before, .appblock.dumping .appblock-icon {
    background: rgba(185, 226, 140, 1);
}
.appblock.sales::before, .appblock.sales .appblock-icon {
    background: rgba(87, 77, 104, 1);
}
.appblock h3 {
    padding: 0;
    margin: 0;
    font-size: 26px;
}
.appblock-number {
    font-size: 72px;
    font-weight: 600;
    line-height: 0;
}
.appblock-desc {
    font-size: 18px;
    padding-left: 10px;
    color: #333;
}
.appblock-icon {
    background-color: aqua;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
.appblock-icon img {
    max-width: 35px;
}

.appgraphblock {
    border-radius: 20px;
    height: 400px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    margin: 10px;
    background-color: #fff;
}
.appgraphblock h3 {
    margin: 10px 0;
}
.shopDot {
    font-size: 13px;
    margin: 0 5px;
}
.shopDot::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: aqua;
    border-radius: 50%;
    margin-right: 5px;
}
.shopDot.oz::before {
    background-color: #0057F5;
}
.shopDot.wb::before {
    background-color: #B02675;
}
.shopDot.ym::before {
    background-color: #FCE000;
}
.percents-block {}