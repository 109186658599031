.monitor-main {
    background-color: rgba(235, 235, 235, 1);
    padding: 20px 24px 24px;
}
.monitor-header {
    display: flex;
    font-weight: 600;
    font-size: 24px;
    align-items: center;
    margin-bottom: 20px;
}
.monitor-logo {
    width: 200px;
    margin-right: 5px;
}
.monitor-left {
    flex-grow: 1;
}

.monitor-right {
    flex: 0 0 300px;
    padding-left: 24px;
}

.monitor-string-main {
    display: flex;
}
.monitor-clock {
    background-color: rgba(57, 115, 103, 1);
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    padding: 8px 14px;
    border-radius: 8px 0 0 8px;
}
.monitor-string {
    background-color: rgb(37, 95, 83);
    height: 45px;
    border-radius: 0 8px 8px 0;
    flex-grow: 1;
    white-space: nowrap;
    width: 100px;
    padding-top: 10px;
    font-size: 20px;
}

.string-item {
    color: #fff;
    margin-right: 20px;
}
.string-item::after {
    content: " • ";
    display: inline-block;
    margin-left: 20px;
}
.monitor-block {
    background: #fff;
    padding: 20px 24px;
    border-radius: 8px;
    height: 300px;
    display: flex;
    margin-bottom: 24px;
}
.compare-monitor {
    background: #fff;
    padding: 10px 24px  20px;
    border-radius: 8px;
    margin-bottom: 24px;
}
.m-leader {
    flex: 0 0 193px;
    padding: 0 10px;
    border-left: 1px solid #ccc;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.m-leader:first-child {
    border: none;
    flex: 0 0 190px;
    padding-left: 0;
}
.m-leader-count {
    font-size: 20px;
    padding: 5px 20px;
    background-color: rgba(235, 235, 235, 1);
    font-weight: 600;
    text-align: center;
}
.m-leader-title {
    font-size: 14px;
}
.m-leader-image {
    max-width: 100px;
    max-height: 100px;
}
.m-index-block {
    width: 100%;
    padding: 0 20px;
    display: flex;
    position: absolute;
    bottom: 0;
    align-items: center;
    justify-content: space-between;
}
.m-index-block-n {
    background-color: rgba(87, 77, 104, 1);
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 10px;
    line-height: 1.4;
}
.m-index-block-i {}

.d-leader {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    border-bottom: 1px solid rgba(179, 179, 179, 1);
    padding: 7px 0;
}
.d-leader:last-child {
    border: none;
    padding-bottom: 0;
}
.d-leader-index {
    display: flex;
    align-items: center;
    font-weight: 600;
}
/**/
.leader {
    flex: 0 0 200px;
}
.index-block {
    display: flex;
    padding: 5px 10px;
    background: #ccc;
    border-radius: 6px;
    width: 80px;
    font-size: 18px;
    font-weight: 600;
    justify-content: space-between;
}
.index-block.up {
    background-color: rgba(185, 226, 140, 1);
}
.index-block.down{
    background-color: rgba(220, 32, 88, 1);
    color: #fff;
}