.counter-widget {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    height: 150px;
    position: relative;
    transition: all .2s;
    margin-bottom: 20px;
}

.counter-widget-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 18px 30px 24px;
}

.counter-widget-before {
    background-color: aqua;
    border-radius: 0 10px 10px 0;
    content: "";
    display: block;
    height: 80px;
    position: absolute;
    top: 35px;
    width: 7px;
}

.counter-widget h3 {
    font-size: 22px;
    margin: 0;
    padding: 0;
}

.counter-widget-icon {
    align-items: center;
    background-color: aqua;
    border-radius: 4px;
    display: flex;
    height: 60px;
    justify-content: center;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 60px;
}

.numberN {
    font-weight: 600;
    font-size: 40px;
    margin-right: 6px;
    line-height: 0;
}

.numberT {
    font-size: 16px;
}