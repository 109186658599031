.login-logo {
    width: 100px;
    height: 100px;
}
.password-block {
    position: relative;
}
.hide-show-password {
    cursor: pointer;
}
.hide-show-password img {
    width: 25px;
    height: 25px;
}