.modal-wrapper {
    position: fixed;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
}
.modal-body {
    background: #fff;
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 20%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    padding: 20px;
}
.modal-body-in {
    position: relative;
}
.modal-close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    right: -40px;
    cursor: pointer;
    z-index: 999;
}

.fs-modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
}
.fs-modal-body {
    background: #fff;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.fs-modal-body-in {
    padding: 24px;
}
.fs-modal-close {
    width: 24px;
    height: 24px;
    cursor: pointer;
}