.calender-arrow {
    flex: 0 0 19px;
    text-align: center;
}
.calender-grid {
    cursor: pointer;
    flex-grow: 1;
    padding: 0 20px;
    display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr  1fr;
  grid-template-rows: 1fr;
  gap: 20px 20px;
  grid-auto-flow: row;
  grid-template-areas:
    ". . ."
    ". . ."
    ". . .";
}
.calender-blockitem {
    padding: 16px 20px;
    border-radius: 10px;
    border: 1px solid #ccc;
}

.calender-date {
    font-weight: 600;
    font-size: 16px;
}

.calender-date span {
    font-size: 38px;
}

.tdspan {
    font-weight: 600;
}
.tdspan.alert {
    color: red;
}